// External
@import 'external/reset';
@import 'external/syntax';

// Fonts
@import 'fonts/text.scss';

// Base
@import 'base/variables';
@import 'base/global';
@import 'base/utility';

// Posts
@import 'layouts/posts';
@import 'layouts/index';
@import 'layouts/tags';
@import 'layouts/search';

// Partials
@import 'includes/header';
@import 'includes/footer';
@import 'includes/tag_list';
@import 'includes/post_nav';

////////////////////////////////////////////////////////////////////////////////
// Customisations to the type theme
////////////////////////////////////////////////////////////////////////////////

// Captions should be centered, and italic'd, with a bit of bottom spacing
figure > figcaption {
  font-style: italic;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 8px;
}

// Center img within a figure
figure > img {
  display: block;
  margin: auto;
}

// Allows us to squeeze 72 characters into the blog content
pre {
  font-size: 0.9em; // 1em -> 0.9em
}

// Cause subsequent lines in a multi-line bullet point to be aligned with the
// first, by placing the bullet marker outside of the list.
ul {
  list-style-position: outside;
  margin-left: 24px; // bump the list markers back inline with the rest of text
}

.post-content {
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;
}
