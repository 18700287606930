.post-content > :last-child {
  margin-bottom: 0;
}
article,
.comments {
  @extend %padding-regular;
  border-bottom: 1px solid $border-color;
  float: left;
  width: 100%;
}
article {
  .subtitle {
    font-size: 1.45em;
    font-weight: normal;
    margin: 0;
  }
  header {
    margin-bottom: 6%;
    text-align: center;
  }
  a {
    @extend .body-link;
  }
  .footnotes {
    font-size: 0.9em;
  }
}
header {
  h1 {
    margin: 0;
  }
}
.meta {
  color: rgba($text-color, .5);
  font-size: 0.9em;
  letter-spacing: 0.1em;
  margin: 0;
  text-transform: uppercase;
}
.feature-image {
  padding: 0%;
  .post-link {
    color: $feature-image-text-color;
  }
  header {
    color: $feature-image-text-color;
    background-size: $feature-image-size;
    margin-bottom: 0;
    padding: $padding-large/2 $padding-large;
    .meta {
      color: rgba($feature-image-text-color, .7);
    }
  }
  .post-content {
    @extend %padding-regular;
  }
}
